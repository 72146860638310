<template>
	<div id="footer-wrapper">
		<footer class="footer paragraph-md">
			<div class="wrapper-base footer__wrapper">
				<nav class="cols _items-justify footer-nav">
					<img class="logo" src="@/assets/img/landing/logo.svg" alt="Dataforce" @click="handleLogoClick">
					<div
						class="col footer-col"
						v-for="col in footerLinks"
						:key="col.name"
					>
						<p class="footer-col__title">{{col.name}}</p>
						<ul class="footer-col__list">
							<li
								v-for="link in col.links"
								:key="link.id"
								class="footer-col__link"
							>
								<router-link
									v-if="$route.name !== ROUTE_NAME.INDEX && link.to || link.to && link.to.name !== ROUTE_NAME.INDEX"
									class="footer-link"
									:to="link.to"
									@click.native="handleRouterLinkClick"
								>
									{{ link.title }}
								</router-link>
								<a
									v-else
									class="footer-link"
									:href="link.href || link.to?.hash"
									:target="link.target || ''"
									@click.prevent="openLink(link)"
								>
									{{ link.title }}
								</a>
							</li>
						</ul>
					</div>
          <ul class="footer-col__list _logo-grant">
            <li>
              <router-link
                  class="logo-grant"
                  :to="{name: ROUTE_NAME.ABOUT_TEAM, hash: '#fasie'}"
              >
                <img src="@/assets/img/landing/fasie_logo.png" alt="FASIE">
              </router-link>
            </li>
            <li class="skolkovo">
              <p>Деятельность осуществляется<br>при грантовой поддержке Фонда «Сколково»</p>
              <a
                class="logo-grant"
                href="https://sk.ru"
                target="_blank"
              >
                <img
                  src="@/assets/img/landing/skolkovo.png"
                  alt="Инновационный центр Сколково"
                />
              </a>
            </li>
          </ul>
				</nav>
				<div class="cols _items-justify">
					<address class="footer__address color_dark-heavy">
						&copy; {{ new Date().getFullYear() }}, DATAFORCE
					</address>
				</div>
			</div>
		</footer>
	</div>
</template>

<script>
import scroll from '@/landing/helpers/scroll.js'
import ROUTE_NAME from '@/router/routeName.js'
export default {
	mixins: [scroll],
	data () {
		return {
			ROUTE_NAME
		}
	},
	computed: {
		config () {
			return this.$store.state.config
		},
		footerLinks () {
			return [
				{
					name: 'О продукте',
					links: [
						{
								title: 'Возможности',
								to: {
										name: ROUTE_NAME.INDEX,
										hash: '#capabilities'
								}
						},
						{
								title: 'Данные',
								to: {
										name: ROUTE_NAME.INDEX,
										hash: '#data'
								}
						},
						{
								title: 'Стоимость',
								to: {
										name: ROUTE_NAME.INDEX,
										hash: '#cost'
								}
						},
						{
								title: 'Материалы',
								to: {
										name: ROUTE_NAME.INDEX,
										hash: '#publications'
								}
						},
						{
								title: 'Презентация',
								href: `${this.config.api.dataforce}/help/df_presentation.pdf`,
								target: '_blank'
						},
            {
              title: 'База знаний',
              href: `https://help.dataforce.io/`,
              target: '_blank'
            }
					]
				},
				{
					name: 'Информация',
					links: [
						{
              title: 'О нас',
              to: {name: ROUTE_NAME.ABOUT_TEAM}
						},
						{
              title: 'Контакты',
              to: {name: ROUTE_NAME.CONTACTS}
						},
            {
              title: 'Политика конфиденциальности',
              to: {name: ROUTE_NAME.CONFIDENTIAL}
            }
					]
				}
			]
		},
	},
}
</script>

<style lang="scss" scoped>
@import '@/styles/mixins.scss';
#footer-wrapper {
	.footer {
		.footer__wrapper {
			padding-top: 80px;
			padding-bottom: 71px;
			display: flex;
			flex-direction: column;
			gap: 26px;
			@include tablets {
				gap: 32px;
				padding-top: 36px;
				padding-bottom: 20px;
			}
		}
		.footer__address {
			font-style: normal;
			font-size: 14px;
			color: #7D7D7D;
			opacity: .8;
		}
		.logo, .logo-grant {
			cursor: pointer;
			img {
				width: 100%;
				height: 100%;
				object-fit: contain;
			}
		}
		.logo {
			width: 160px;
			height: 38px;
			object-fit: contain;
			@include tablets {
				width: 151px;
				height: 36px;
			}
		}

		.logo-grant {
      display: block;
      width: 157px;
      height: 88px;
			img {
				object-fit: contain;
			}
			@include tablets {
				width: 117px;
				height: 66px;

        &._skolkovo {
          margin: 0;
        }
			}
		}

    .skolkovo {
      display: flex;
      position: relative;
      margin-top: 60px;

      @media all and (max-width: 1165px) {
        margin-top: 130px;
      }

      @media all and (max-width: 786px) {
        align-items: center;
        column-gap: 32px;
        margin-top: 0;
      }

      p {
        position: absolute;
        top: 50%;
        right: calc(100% + 32px);
        width: 302px;
        color: #4d5759;
        transform: translate(0, -50%);

        @media all and (max-width: 786px) {
          position: static;
          transform: unset;
        }
      }
    }

		.footer-col {
			&__title {
				font-weight: 500;
				font-size: 16px;
				line-height: 150%;
				color: #2D3646;
				margin-bottom: 20px;
			}

			&__list {
				display: flex;
				flex-direction: column;
				gap: 12px;

				.footer-link {
					text-decoration: none;
					color: #455165;
					line-height: 150%;

					&:hover {
						color: #2979FF;
					}
				}

        &._logo-grant {
          gap: 20px
        }
			}
		}

		.footer-nav {
			@include tablets {
				flex-direction: column;
				gap: 44px;
			}
		}
	}
}
</style>
