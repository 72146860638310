import ROUTE_NAME from '@/router/routeName'
import DFHeader from '../components/common/header'
import DFFooter from '../components/common/footer'

export default {
    metaInfo: () => ({
       title: 'DATAFORCE - Платформа для управления ростом эффективности сайтов онлайн ритейла и маркетплейсов'
    }),

    components: {
      DFHeader,
      DFFooter
    },

    data: () => ({
        ROUTE_NAME,
    }),
}
